import * as React from "react"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function Essays(props) {
  const { data } = props

  return (
    <main>
      <Helmet>
        <title>Essays</title>
      </Helmet>
      <section className="container">
        <div className="pt-6 pb-8 space-y-2 md:space-y-5">
          <h1>Essays</h1>
          <p className="text-lg leading-6 text-zinc-500">
            My latest ideas synthesized into essays on software and life.
          </p>
        </div>
        <ul className="space-y-7 sm:space-y-12">
          {data.posts.nodes.map((post) => (
            <li className="p-6 sm:p-8 rounded-3xl hover-card">
              <Link
                to={post.slug}
                aria-label={`Read ${post.frontmatter.title}`}
                className="text-amber-500 hover:text-amber-600"
              >
                <article className="space-y-2 xl:grid xl:grid-cols-4 xl:space-y-0 xl:items-baseline">
                  <dl>
                    <dt className="sr-only">Last updated</dt>
                    <dd className="text-base leading-6 font-medium text-zinc-500">
                      <time datetime={post.frontmatter.dateString}>
                        {post.frontmatter.date}
                      </time>
                    </dd>
                  </dl>
                  <div className="space-y-5 xl:col-span-3">
                    <div className="space-y-6">
                      <h2 className="text-2xl leading-8 font-bold tracking-tight">
                        <Link to={post.slug}>{post.frontmatter.title}</Link>
                      </h2>
                      <div className="prose max-w-none text-zinc-500 font-normal">
                        {post.excerpt}
                      </div>
                    </div>
                    <div className="text-base leading-6 font-medium">
                      <Link
                        to={post.slug}
                        aria-label={`Read ${post.frontmatter.title}`}
                        className="text-amber-500 hover:text-amber-600"
                      >
                        Read more →
                      </Link>
                    </div>
                  </div>
                </article>
              </Link>
            </li>
          ))}
        </ul>
        <div className="illustration">
          <StaticImage
            alt="Lighthouse illustration"
            src="../images/lighthouse.png"
            layout="FIXED"
            width={140}
            placeholder="TRACED_SVG"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </section>
    </main>
  )
}

export const pageQuery = graphql`
  query AllPostsQuery {
    posts: allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      totalCount
      nodes {
        id
        slug
        excerpt
        frontmatter {
          title
          date(formatString: "MMMM D, yyyy")
          dateString: date
        }
      }
    }
  }
`
